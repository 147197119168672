import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Box } from '@walltowall/calico'

import { PostTemplateQuery } from '../graphqlTypes'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

import { AuthorStamp } from '../components/AuthorStamp'
import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Layout } from '../components/Layout'
import { PageIntro } from '../components/PageIntro'
import { SEO } from '../components/SEO'
import { PrevNextPost } from '../components/PrevNextPost'

type PostTemplatePageContext = {
  ogImageAbsolutePath?: string
}

export const PostTemplate = ({
  data,
  path,
  pageContext,
}: PageProps<PostTemplateQuery, PostTemplatePageContext>) => {
  const siteMetadata = useSiteMetadata()
  const post = data.markdownRemark
  const frontmatter = post?.frontmatter
  const author = frontmatter?.author
  const authorImageFluid = author?.image?.childImageSharp?.fluid

  /* const ogImageAbsolutePath = pageContext?.ogImageAbsolutePath */

  const nextPost = data.nextPost
  const previousPost = data.previousPost

  return (
    <Layout>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.excerpt}
        url={siteMetadata.siteUrl + path}
        imageURL={
          frontmatter?.ogImage?.publicURL &&
          siteMetadata.siteUrl + frontmatter.ogImage.publicURL
        }
        imageWidth={1200}
        imageHeight={630}
        // imageURL={ogImage && siteMetadata.siteUrl + '/' + ogImage.path}
        // imageWidth={ogImage?.size.width}
        // imageHeight={ogImage?.size.height}
        imageAlt={[frontmatter?.title, frontmatter?.excerpt]
          .filter(Boolean)
          .join(' — ')}
      />
      <BoundedBox as="article" styles={{ paddingBottom: [20, 25, 30] }}>
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box styles={{ marginBottom: [8, 10, 12] }}>
            <PageIntro
              title={frontmatter?.title}
              excerpt={frontmatter?.excerpt}
            />
          </Box>
          {author && (
            <Box styles={{ marginBottom: [8, 10, 12] }}>
              <AuthorStamp
                href={author?.fields?.url}
                imageFluid={authorImageFluid}
                name={author?.name}
                title={author?.title}
                date={frontmatter?.date}
              />
            </Box>
          )}
          <Box styles={{ maxWidth: 'full' }}>
            <HTMLContent
              html={post?.html}
              styles={{ maxWidth: 'small', color: 'gray10' }}
            />
          </Box>
        </Box>
      </BoundedBox>
      {(previousPost || nextPost) && (
        <PrevNextPost
          previousHref={previousPost?.fields?.url}
          previousTitle={previousPost?.frontmatter?.title}
          nextHref={nextPost?.fields?.url}
          nextTitle={nextPost?.frontmatter?.title}
        />
      )}
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query PostTemplate($id: String!, $nextId: String, $previousId: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        excerpt
        author {
          fields {
            url
          }
          image {
            childImageSharp {
              fluid(maxWidth: 96) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          title
        }
        date(formatString: "MMM D, YYYY")
        ogImage {
          publicURL
        }
      }
      excerpt
      html
    }
    nextPost: markdownRemark(id: { eq: $nextId }) {
      fields {
        url
      }
      frontmatter {
        title
      }
    }
    previousPost: markdownRemark(id: { eq: $previousId }) {
      fields {
        url
      }
      frontmatter {
        title
      }
    }
  }
`
