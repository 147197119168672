import * as React from 'react'
import HTMLRenderer, { HTMLRendererProps } from 'react-html-renderer'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import clsx from 'clsx'
import _slugify from 'slugify'

import { useUtilStyles } from '../hooks/useUtilStyles'

import { Heading } from './Heading'
import { Anchor } from './Anchor'
import { Text } from './Text'
import { Divider } from './Divider'

import * as styleRefs from './HTMLContent.treat'

const baseHeadingStyles = {
  marginTop: [9, 11, 14],
  marginBottom: [5, 6, 7],
  color: 'black',
} as const

const baseTextStyles = {
  marginBottom: [8, 9, 10],
} as const

const slugify = (children: React.ReactNode): string | undefined =>
  typeof children === 'string' ? _slugify(children, { lower: true }) : undefined

const components: HTMLRendererProps['components'] = {
  h1: (props) => {
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="sansCondB"
        level={2}
        className={firstLastNoMargin}
        id={slugify(props.children)}
        {...props}
        styles={{ ...baseHeadingStyles, ...props.styles }}
      />
    )
  },
  h2: (props) => {
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="sansCondC"
        level={3}
        className={firstLastNoMargin}
        id={slugify(props.children)}
        {...props}
        styles={{ ...baseHeadingStyles, ...props.styles }}
      />
    )
  },
  h3: (props) => {
    const { firstLastNoMargin } = useUtilStyles()

    return (
      <Heading
        variant="sansCondC"
        level={4}
        className={firstLastNoMargin}
        id={slugify(props.children)}
        {...props}
        styles={{ ...baseHeadingStyles, color: 'gray50', ...props.styles }}
      />
    )
  },
  p: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Text
        as="p"
        variant="serif-17-24"
        className={lastNoMargin}
        {...props}
        styles={{ ...baseTextStyles, ...props.styles }}
      />
    )
  },
  ul: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Box
        as="ul"
        className={lastNoMargin}
        {...props}
        styles={{
          ...baseTextStyles,
          paddingLeft: [7, 10],
          listStyle: 'disc',
          ...props.styles,
        }}
      />
    )
  },
  ol: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Box
        as="ol"
        className={lastNoMargin}
        {...props}
        styles={{
          ...baseTextStyles,
          paddingLeft: [7, 10],
          listStyle: 'decimal',
          ...props.styles,
        }}
      />
    )
  },
  li: (props) => {
    const { lastNoMargin } = useUtilStyles()

    return (
      <Text
        as="li"
        variant="serif-17-24"
        className={lastNoMargin}
        {...props}
        styles={{
          display: 'listItem',
          marginBottom: [4, 5],
          ...props.styles,
        }}
      />
    )
  },
  a: ({ href, ...props }) => <Anchor href={href!} {...props} />,
  strong: (props) => (
    <Text
      as="strong"
      {...props}
      styles={{ fontWeight: 'semibold', ...props.styles }}
    />
  ),
  blockquote: (props) => (
    <Box
      as="blockquote"
      {...props}
      styles={{
        ...baseTextStyles,
        color: 'gray50',
        borderColor: 'gray80',
        borderStyle: 'solid',
        borderWidth: 'none',
        borderLeftWidth: '4px',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingLeft: [4, 7, 8],
        fontStyle: 'italic',
        marginLeft: [0, 0, -8],
        ...props.styles,
      }}
    />
  ),
  hr: ({ children: _children, ...props }) => (
    <Divider
      color="gray80"
      {...props}
      styles={{
        height: '1px',
        marginTop: [13, 16, 21],
        marginBottom: [13, 16, 21],
        width: '6/12',
        marginLeft: 'auto',
        marginRight: 'auto',
        ...props.styles,
      }}
    />
  ),
  code: ({ class: className, ...props }) => {
    const styles = useStyles(styleRefs)

    return (
      <Box
        as="code"
        className={clsx(styles.code, className)}
        {...props}
        styles={{
          fontFamily: 'mono',
          fontSize: '0.7em',
          backgroundColor: 'gray95',
          borderRadius: '3px',
          paddingTop: [0.5, 1],
          paddingBottom: [0.5, 1],
          paddingLeft: [1, 1.5],
          paddingRight: [1, 1.5],
          textTransform: 'none',
          ...props.styles,
        }}
      />
    )
  },
  pre: ({ class: className, ...props }) => (
    <Box
      className="no-vscode-bg"
      styles={{
        ...baseTextStyles,
        display: 'flex',
        backgroundColor: 'gray10',
        overflow: 'auto',
        borderRadius: [null, null, '3px'],
        marginLeft: [-4, -6, -7],
        marginRight: [-4, -6, -7],
        ...props.styles,
      }}
    >
      <Box
        as="pre"
        styles={{
          paddingTop: [8, 7, 8],
          paddingBottom: [8, 7, 8],
          paddingRight: [4, 7, 8],
          paddingLeft: [4, 7, 8],
        }}
      >
        <Text variant="code" className={className} {...props} />
      </Box>
    </Box>
  ),
}

export type HTMLContentProps = {
  html?: HTMLRendererProps['html']
  componentOverrides?: HTMLRendererProps['componentOverrides']
} & BoxProps

export const HTMLContent = ({
  html,
  componentOverrides,
  ...props
}: HTMLContentProps) => (
  <Box {...props}>
    <HTMLRenderer
      html={html}
      components={components}
      componentOverrides={componentOverrides}
    />
  </Box>
)
