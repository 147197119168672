import * as React from 'react'
import { Box } from '@walltowall/calico'

import { preventWidow } from '../lib/preventWidow'

import { Heading } from './Heading'
import { Text } from './Text'

const variants = {
  titleCaps: {
    headingVariant: 'sansCondACaps',
  },
  default: {
    headingVariant: 'sansCondA',
  },
} as const

type PageIntroProps = {
  variant?: keyof typeof variants
  title?: string
  excerpt?: string
}

export const PageIntro = ({
  variant: variantName = 'default',
  title,
  excerpt,
}: PageIntroProps) => {
  const variant = variants[variantName]

  return (
    <Box
      styles={{
        display: 'grid',
        justifyItems: 'center',
        gap: [4, 6],
      }}
    >
      {title && (
        <Heading
          level={1}
          variant={variant.headingVariant}
          styles={{ textAlign: 'center', maxWidth: '65ch' }}
        >
          {title}
        </Heading>
      )}
      {excerpt && (
        <Text
          as="p"
          variant="sans-16-20"
          styles={{ textAlign: 'center', maxWidth: '65ch', color: 'gray50' }}
        >
          {preventWidow(excerpt, 3)}
        </Text>
      )}
    </Box>
  )
}
