import * as React from 'react'
import { useBoxStyles } from '@walltowall/calico'
import clsx from 'clsx'

import { Link, LinkProps } from './Link'

export type AnchorProps = LinkProps

export const Anchor = ({ className, ...props }: AnchorProps) => {
  const classNames = clsx(
    useBoxStyles({ textDecoration: 'underline' }),
    className,
  )

  return <Link {...props} className={classNames} />
}
