import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet-async'

interface SEOProps {
  title?: string
  description?: string
  url?: string
  lang?: string
  meta?: JSX.IntrinsicElements['meta'][]
  imageURL?: string
  imageWidth?: number
  imageHeight?: number
  imageAlt?: string
}

export const SEO = ({
  description: providedDescription,
  url,
  lang,
  meta = [],
  title,
  imageURL,
  imageWidth,
  imageHeight,
  imageAlt,
}: SEOProps) => {
  const queryData = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          description
        }
      }
    }
  `)

  const description =
    providedDescription ?? queryData?.site?.siteMetadata?.description

  return (
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:image',
          content: imageURL,
        },
        {
          property: 'og:image:width',
          content: imageWidth,
        },
        {
          property: 'og:image:height',
          content: imageHeight,
        },
        {
          property: 'og:image:alt',
          content: imageAlt,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image',
          content: imageURL,
        },
        {
          name: 'twitter:image:alt',
          content: imageAlt,
        },
        ...meta,
      ].filter(Boolean)}
    >
      {lang && <html lang={lang} />}
    </Helmet>
  )
}
