import * as React from 'react'
import { Box, useBoxStyles, usePseudoBoxStyles } from '@walltowall/calico'

import { Link } from './Link'
import { Heading } from './Heading'
import { Text } from './Text'
import { Icon } from './Icon'
import { Divider } from './Divider'
import clsx from 'clsx'

const variants = {
  previous: {
    iconName: 'chevronLeft',
    iconColumn: 1,
    metadataColumn: 2,
    gridTemplateColumns: 'auto 1fr',
    label: 'Previous article',
    textAlign: 'left',
  },
  next: {
    iconName: 'chevronRight',
    iconColumn: 2,
    metadataColumn: 1,
    gridTemplateColumns: '1fr auto',
    label: 'Next article',
    textAlign: 'right',
  },
} as const

type PostLinkProps = {
  variant: keyof typeof variants
  title: string
  href: string
}

const PostLink = ({ variant: variantName, title, href }: PostLinkProps) => {
  const variant = variants[variantName]

  const linkClassNames = clsx(
    useBoxStyles({
      display: 'flex',
      alignItems: 'center',
      flex: 'equal0',
      transitionDuration: 'normal',
      transitionProperty: 'backgroundColor',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles({ backgroundColor: 'gray95' }, 'focus'),
    usePseudoBoxStyles({ backgroundColor: 'gray95' }, 'hover'),
  )

  return (
    <Link href={href} className={linkClassNames}>
      <Box
        styles={{
          display: 'grid',
          alignItems: 'center',
          width: 'full',
          gridTemplateColumns: variant.gridTemplateColumns,
          gap: 4,
          paddingTop: 7,
          paddingBottom: 7,
          paddingLeft: [4, 5, 7],
          paddingRight: [4, 5, 7],
        }}
      >
        <Icon
          name={variant.iconName}
          styles={{
            color: 'gray50',
            width: '1rem',
            gridRow: 1,
            gridColumn: variant.iconColumn,
          }}
        />
        <Box
          styles={{
            display: 'grid',
            gap: 2.5,
            gridRow: 1,
            gridColumn: variant.metadataColumn,
            textAlign: variant.textAlign,
          }}
        >
          <Heading variant="sansCondC" as="span">
            {title}
          </Heading>
          <Text as="span" variant="sans-16" styles={{ color: 'gray50' }}>
            {variant.label}
          </Text>
        </Box>
      </Box>
    </Link>
  )
}

type PrevNextPostProps = {
  previousTitle?: string
  previousHref?: string
  nextTitle?: string
  nextHref?: string
}

export const PrevNextPost = ({
  previousTitle,
  previousHref,
  nextTitle,
  nextHref,
}: PrevNextPostProps) => (
  <Box
    styles={{
      borderWidth: 'none',
      borderStyle: 'solid',
      borderTopWidth: '1px',
      borderColor: 'gray80',
      display: 'flex',
    }}
  >
    {previousTitle && previousHref ? (
      <PostLink variant="previous" title={previousTitle} href={previousHref} />
    ) : (
      <Box styles={{ flex: 'equal0' }} />
    )}
    <Divider color="gray80" styles={{ width: '1px', height: 'auto' }} />
    {nextTitle && nextHref ? (
      <PostLink variant="next" title={nextTitle} href={nextHref} />
    ) : (
      <Box styles={{ flex: 'equal0' }} />
    )}
  </Box>
)
